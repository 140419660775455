import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EmailEditorService {
  constructor(private http: HttpClient) {}

  storeDesign(design) {
    const data = new FormData();
    data.append('content', design);
    return this.http
      .post('http://fish.cloudbytes.ro/api/add-design', data)
      .subscribe((res) => console.log(res));
  }

  storeDesignToLoad(designToLoad, design) {
    const data = new FormData();
    data.append('content', design);
    data.append('design_to_load', designToLoad);
    return this.http
      .post('http://fish.cloudbytes.ro/api/store-design-to-load', data)
      .subscribe((res) => console.log(res));
  }

  getDataToLoad() {
    return this.http.get('http://fish.cloudbytes.ro/api/get-design');
  }
}
