import {AfterViewInit, Component, ViewChild} from '@angular/core';


import sample from './sample.json';
import {EmailEditorComponent} from '../../projects/email-editor/src/lib/email-editor.component';
import {EmailEditorService} from '../../projects/email-editor/src/lib/email-editor.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'angular-email-editor';

  options = {};

  constructor(private emailService: EmailEditorService) {

  }

  @ViewChild('editor')
  private emailEditor: EmailEditorComponent;

  editorLoaded() {
    this.emailEditor.loadDesign(sample);
  }

  ngAfterViewInit() {
    // let e: any = this.emailEditor;


  }

  saveDesign() {
    this.emailEditor.saveDesign(
      (dataToLoad) => {
        this.emailEditor.exportHtml(
          (dataToExport:any) => {
            console.log(dataToExport);
            // this.emailService.storeDesign();
            // console.log('exportHtml', data);
            // console.log('saveDesign', dataToLoad);
            this.emailService.storeDesignToLoad(JSON.stringify(dataToLoad), dataToExport.html);
          }
        );
      }
    );
  }

  exportHtml() {

    this.emailEditor.exportHtml(
      (data) => {
        // this.emailService.storeDesign(JSON.stringify(data.html));
        // console.log('exportHtml', data);
      }
    );
  }
}
