import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { loadScript } from './loadScript';
import pkg from './source.json';
import { EmailEditorService } from './email-editor.service';

declare module unlayer {
  function init(object);

  function createEditor(object);

  function loadDesign(object);

  function saveDesign(Function);

  function exportHtml(Function);
}

export interface appearance {
  projectId?: number;
  tools?: object;
  appearance?: object;
  locale?: string;
}

let lastEditorId = 0;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'email-editor',
  templateUrl: './email-editor.component.html',
  styleUrls: ['./email-editor.component.css'],
})
export class EmailEditorComponent implements OnInit, AfterViewInit {
  @Input() editorId: string;
  @Input() options: any = {};
  @Input() projectId: number;
  @Input() tools: object = {
    social: {
      enabled: true,
    },
  };
  @Input() appearance: object;
  @Input() locale: string;

  @Input() minHeight = '500px';

  @Output() loaded = new EventEmitter();

  editor: any;

  constructor(private emailEditorService: EmailEditorService) {
    this.editorId = `editor-${++lastEditorId}`;
  }

  ngOnInit() {
    loadScript(this.loadEditor.bind(this));
  }

  ngAfterViewInit() {}

  protected loadEditor() {
    const options: any = this.options || {};

    if (this.projectId) {
      options.projectId = this.projectId;
    }

    if (this.tools) {
      options.tools = this.tools;
    }

    if (this.appearance) {
      options.appearance = this.appearance;
    }

    if (this.locale) {
      options.locale = this.locale;
    }

    this.editor = unlayer.createEditor({
      ...options,
      id: this.editorId,
      displayMode: 'email',
      source: {
        name: pkg.name,
        version: pkg.version,
      },
    });
    // let e: any = unlayer;
    // this.editor.registerCallback('image', function (file, done) {
    //   console.log(file);
    // });

    this.editor.registerColumns([2, 2, 2, 2, 2, 2]);
    this.editor.registerColumns([1, 2, 2, 2, 2, 2, 1]);
    this.loaded.emit({});
  }

  public loadDesign(data: object) {
    this.emailEditorService.getDataToLoad().subscribe((response: any) => {
      console.log(response);
      this.editor.loadDesign(JSON.parse(response));
    });
    // let myData = JSON.stringify(data);
    // console.log(data);
  }

  public saveDesign(cb: (data: object) => void) {
    this.editor.saveDesign(cb);
  }

  public exportHtml(cb: (data: object) => void) {
    this.editor.exportHtml(cb);
  }
}
