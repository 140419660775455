import { NgModule } from '@angular/core';
import { EmailEditorComponent } from './email-editor.component';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [EmailEditorComponent],
  imports: [
    HttpClientModule
  ],
  exports: [EmailEditorComponent]
})
export class EmailEditorModule { }
